<template>
  <div
    class="relative"
    :class="[
      data.background === BACKGROUNDS.BLUE && 'bg-blue-500 text-white',
      data.background === BACKGROUNDS.GRAY && 'bg-gray-100 text-neutral-800',
      data.background === BACKGROUNDS.GREEN && 'bg-teal-500 text-neutral-800',
      data.background === BACKGROUNDS.WHITE && 'bg-white text-neutral-800',
      data.background === BACKGROUNDS.COLORFUL && 'text-white',
      data.background === BACKGROUNDS.ROCKET && 'text-white',
      data.background === BACKGROUNDS.FORREST && 'text-white'
    ]"
  >
    <Image
      v-if="backgroundImage"
      :src="backgroundImage"
      :width="2800"
      :height="440"
      class="absolute -z-1 h-full w-full object-cover"
      :class="
        backgroundImage === colorfulBg
        ? 'object-center'
        : ''
      "
      alt="CTA Background"
    />
    <div class="container flex items-center justify-between gap-3 py-8 lg:h-[200px]">
      <div class="font-mono text-3xl-ub lg:text-6xl-ub">{{ data.title }}</div>
      <Button
        :label="data.linkText ? data.linkText : $t('go')"
        :size="isLG ? 'default' : 'medium'"
        :to="localePathByType(data.link?.__typename as string, data.link?.slug as string)"
        :theme="buttonTheme"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { theme } from '#tailwind-config'
import type { SectionCtaRecord } from '~/types/generated'
import rocketBg from '~/assets/images/backgrounds/cta-rocket.webp'
import forrestBg from '~/assets/images/backgrounds/cta-forrest.webp'
import colorfulBg from '~/assets/images/backgrounds/cta-colorful.webp'

enum BACKGROUNDS {
  WHITE = 'White',
  GRAY = 'Gray',
  GREEN = 'Green',
  BLUE = 'Blue',
  COLORFUL = 'Colorful',
  FORREST = 'Forrest',
  ROCKET = 'Rocket'
}

const { localePathByType } = useLocalePathByType()

const props = defineProps({
  data: {
    type: Object as PropType<SectionCtaRecord>,
    default: () => {}
  }
})
const breakpoints = useBreakpoints(theme.screens)
const isLG = breakpoints.greater('lg')

const buttonTheme = computed(() => {
  switch (props.data.background) {
    case BACKGROUNDS.BLUE:
      return 'dark'
    case BACKGROUNDS.COLORFUL:
      return 'dark'
    case BACKGROUNDS.ROCKET:
      return 'dark'
    case BACKGROUNDS.FORREST:
      return 'dark'
    default:
      return 'light'
  }
})

const backgroundImage = computed(() => {
  switch (props.data.background) {
    case BACKGROUNDS.COLORFUL:
      return colorfulBg
    case BACKGROUNDS.ROCKET:
      return rocketBg
    case BACKGROUNDS.FORREST:
      return forrestBg
    default:
      return undefined
  }
})
</script>
